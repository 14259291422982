import React, { useEffect, useState } from "react";
import axios from "axios";
import './register.css';
import { CircularProgress, Typography, Button, Snackbar, Alert } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha'

// api url
const apiUrl = process.env.REACT_APP_API_URL;

const Login = ({setInstance}) => {
    const searchParams = new URLSearchParams(useLocation().search);
    const token = searchParams.get('token');

    const [status, setStatus] = useState(false);
    const [firstStep, setFirstStep] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingToken, setLoadingToken] = useState(false);
    const [open, setOpenMessage] = React.useState(false);
    const [tagMessage, setTagMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [captchaVal, setCaptchaVal] = useState(null);
    
    // estados para validação de senha segura
    const [lowerCase, setLowerCase] = useState(false);
    const [upperCase, setUpperCase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [specialChars, setSpecialChars] = useState(false);
    const [miniumLength, setMiniumLength] = useState(false);

    const navigate = useNavigate();
    document.title = 'Solleana ERP x AICE :: Login';

    // Função para validar senha
    const validateSafetyPassword = (value) => {
        value.match(/[a-z]/g) !== null ? setLowerCase(true) : setLowerCase(false);
        value.match(/[A-Z]/g) !== null ? setUpperCase(true) : setUpperCase(false);
        value.match(/[0-9]/g) !== null ? setHasNumber(true) : setHasNumber(false);
        value.match(/[-!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g) ? setSpecialChars(true) : setSpecialChars(false);
        value.length >= 8 ? setMiniumLength(true) : setMiniumLength(false);
    }
    
    const closeSnack = (event, reason) => {
        if (reason === 'clickaway') { return; }
        setOpenMessage(false);
    };

    // Registrando usuário
    const submitRegister = (e) => {
        e.preventDefault();
        if(!lowerCase || !upperCase || !hasNumber || !specialChars || !miniumLength) { 
            setTagMessage(`Preencha todos os requisitos de senha!`);
            setMessageType('error');
            setOpenMessage(true); 
        } else if(!captchaVal) {
            setTagMessage(`reCAPTCHA inválido`);
            setMessageType('error');
            setOpenMessage(true);
        }
    }

    useEffect(() => {
        const fetchToken = async () => {
            setLoadingToken(true);
            const tokenData = await axios.get(`${apiUrl}/v1/checkTokenIsAvailable/?token=${token}`, {withCredentials:true});
            if(tokenData.request.status === 200) { setEmail(tokenData.data.decoded.email); setStatus(true); setLoadingToken(false); } else { setStatus(false); setLoadingToken(false); }
            
        }

        fetchToken();
    }, []);

    // Renderizando
    return (
        <>
            <main>
                <section className="main-frame">
                    <svg className="shapes" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 600">
                        <circle cx="200" cy="150" r="100" fill="rgba(255, 255, 255, 0.2)" />
                        <rect x="500" y="300" width="150" height="150" fill="rgba(255, 255, 255, 0.1)" transform="rotate(45 600 375)" />
                        <polygon points="400,50 450,150 350,150" fill="rgba(255, 255, 255, 0.15)" />
                    </svg>

                    <div className="register-wrapper">
                        {loadingToken ? (<>
                            <CircularProgress />
                        </>) : (<>
                            {status ? (
                            <>
                                <img src={`/imgs/new-logo.png`} alt="" width={235} style={{marginBottom: '20px'}}/>
                                <form onSubmit={submitRegister}>
                                    <h1>Cadastro</h1>
                                    <label className='column-align'>
                                        <span>Email :</span>
                                        <i className="fa-solid fa-user"></i>
                                        <input type='email' id='user' name='user' value={email} onChange={(e) => setEmail(e.target.value)} required placeholder='exemplo@dominio.com.br'/>
                                    </label>
                                    <label className='column-align'>
                                        <span>Senha :</span>
                                        <i className="fa-solid fa-key"></i>
                                        <input type='password' id='pass' name='pass' value={password} onChange={(e) => {setPassword(e.target.value); validateSafetyPassword(e.target.value)}} required maxLength={18} placeholder='Digite sua senha'/>
                                    </label>

                                    <label className="steps-password-safety">
                                        <p><span>{lowerCase ? <CheckCircleIcon sx={{ color: '#008000', fontSize: '1rem' }} /> : <CancelIcon sx={{ color: '#ff0000', fontSize: '1rem' }} />}</span>Contém letras minúsculas</p>
                                        <p><span>{upperCase ? <CheckCircleIcon sx={{ color: '#008000', fontSize: '1rem' }} /> : <CancelIcon sx={{ color: '#ff0000', fontSize: '1rem' }} />}</span>Contém letras maiúsculas</p>
                                        <p><span>{hasNumber ? <CheckCircleIcon sx={{ color: '#008000', fontSize: '1rem' }} /> : <CancelIcon sx={{ color: '#ff0000', fontSize: '1rem' }} />}</span>Contém números</p>
                                        <p><span>{specialChars ? <CheckCircleIcon sx={{ color: '#008000', fontSize: '1rem' }} /> : <CancelIcon sx={{ color: '#ff0000', fontSize: '1rem' }} />}</span>Contém caracteres especiais</p>
                                        <p><span>{miniumLength ? <CheckCircleIcon sx={{ color: '#008000', fontSize: '1rem' }} /> : <CancelIcon sx={{ color: '#ff0000', fontSize: '1rem' }} />}</span>Minímo de 8 caracteres</p>
                                    </label>

                                    <Button type="button">
                                        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} onChange={val => setCaptchaVal(val)} />
                                    </Button>
                                    <Button type='submit' className='login-btn' disabled={loading}> {loading && <CircularProgress size="1.2rem" color="inherit" sx={{marginRight: '10px'}}/>} Prosseguir</Button>
                                </form>
                            </>
                            ) : (<h1 style={{ height: '50%', display:'flex', alignItems: 'center' }}>Acesso inválido. Entre em contato com o suporte para receber um novo acesso.</h1>)}
                        </>)}                        
                    </div>

                    <div className="copyright-section">
                        <Typography className="copy-span-title" sx={{ fontWeight: 600, color: 'black'}}>Sistema baseado no ERP chamado Solleana</Typography>
                        <Typography className="copy-span-desc" sx={{ fontWeight: 600, color: 'black'}}>CodedBytes x AICE &copy; All Rights Reserved.</Typography>
                    </div>

                    <Snackbar open={open} autoHideDuration={5000} onClose={closeSnack}>
                        <Alert
                            onClose={closeSnack}
                            severity={messageType}
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            {tagMessage}
                        </Alert>
                    </Snackbar>
                </section>
            </main>
        </>
    )
}

export default Login;