import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { useLocation } from 'react-router-dom';

// URL do servidor Socket.IO
const SOCKET_URL = process.env.REACT_APP_API_URL;

// Criando o contexto para o socket
const SocketContext = createContext(null);

// Hook para acessar o socket em qualquer lugar
export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [socket, setSocket] = useState(null);
    const location = useLocation();

    useEffect(() => {
        // Função para conectar o socket
        const connectWS = () => {
            // Evita criar uma nova conexão se já houver uma ativa
            if (!socket && location.pathname !== '/login') {
                const socketIo = io(SOCKET_URL, {
                    transports: ['websocket'],
                    withCredentials: true,
                });

                setSocket(socketIo);

                // Limpa o socket quando o componente desmonta ou a rota muda
                return () => { if (socketIo) { socketIo.disconnect(); } };
            }
        };

        connectWS();

        // Desconecta o socket se a rota for "/login"
        if (location.pathname === '/login' && socket) {
            socket.disconnect();
            setSocket(null);
        }
    }, [location.pathname, socket]);

    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
