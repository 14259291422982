import React, { createContext, useContext, useEffect, useState } from 'react';

// Criando o contexto para o socket
const LanguageContext = createContext(null);

// Hook para acessar o socket em qualquer lugar
export const useLanguage = () => {
    return useContext(LanguageContext);
};

export const LanguageProvider = ({ children, lang }) => {
    // eslint-disable-next-line no-unused-vars
    const [language, setLanguage] = useState(null);

    useEffect(() => {
        const pushLang = async (lang) => {
            try {
                const response = await fetch(`/locales/${lang}/translation.json`);

                if (!response.ok) {
                    throw new Error(`Erro HTTP: ${response.status}`);
                } else {
                    const data = await response.json()
                    await setLanguage(data);
                    localStorage.setItem('$_lang', lang);
                }
            } catch (error) { console.error("Erro ao carregar traduções:", error); }
        };

        pushLang(lang);

        // Retorna uma função de limpeza (aqui não é necessária, mas boa prática)
        return () => {};
    }, [lang]);

    return (
        <LanguageContext.Provider value={language}>
            {children}
        </LanguageContext.Provider>
    );
};
