// App.js
import React, { createElement, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './paginas/login/login';
import Register from './paginas/register/register';
import NotFoundPage from './paginas/404/404';
import EnableModule from './paginas/EnableModule/EnableModule';
import SettingsPage from './paginas/Settings/settingsPage';
import Home from './paginas/Home/Home';
import CheckRootRoute from './componentes/CheckRootRoute';
import CartProvider from './addons/Carrinho Aice/cartContext';
import axios from 'axios';
import { Alert, CircularProgress, Snackbar } from '@mui/material';
import MUINavBar from './componentes/MUINavBar';
import LoginRouterCheck from './componentes/LoginRouterCheck';
import RedirectInstance from './componentes/RedirectToInstance';
import UseWarnings from './componentes/CustomWarnings/useWarnings';
import { useSocket,SocketProvider } from './componentes/socketContext';
import { useNavigate } from 'react-router-dom';
import { LanguageProvider } from './componentes/languageContext';
import { useLanguage } from './componentes/languageContext';

// api url
const apiUrl = process.env.REACT_APP_API_URL;

const RenderSuspense = ({ viewPath, viewName, setLoaded, loadView }) => {
  // Loading de modulos
  useEffect(() => {
    setLoaded(true); 
    return () => setLoaded(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<div className='loadingModule'><CircularProgress size="3rem" /></div>}>
      {createElement(loadView(viewPath))}
    </Suspense>
  );
};

// Módulo
const App = () => {
  const [modules, setModules] = useState([]);
  const [lang, setLang] = useState('');
  const language = useLanguage();

  useEffect(() => {
    // PEGAR PRIMEIRO A LINGUAGEM NO LOCALSTORAGE, COMO NÃO TEMOS AINDA, VOU PEGAR A PADRÃO QUE É PT_BR
    (!localStorage.getItem('$s_lang')) ? setLang('pt_br') : setLang(localStorage.getItem('$s_lang'));
  }, [])

  if(lang === '' || lang === undefined) { return (<div className='loadingModule'><CircularProgress size="3rem" /><span> Loading language... </span></div>) }
  else return (
    <CartProvider modules={modules}>
        <Router>
        <SocketProvider>
          <LanguageProvider lang={lang}>
          <Routes>
            <Route path="register" element={<LoginRouterCheck><Register /></LoginRouterCheck>} />
            <Route path="login" element={<LoginRouterCheck><Login /></LoginRouterCheck>} />
            <Route path="/*" element={<LocalRoutes modules={modules} setModules={setModules}/>} />
          </Routes>
          </LanguageProvider>
        </SocketProvider>
        </Router>
      </CartProvider>
  );
};

const LocalRoutes = ({modules, setModules}) => {
  const [open, setOpenMessage] = useState(false);
  const [tagMessage, setTagMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [addons, setAddons] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { addToQueue } = UseWarnings();
  const socket = useSocket();
  const navigate = useNavigate();

  useEffect(() => {
    if (socket) {
        // Escutando por mensagens recebidas do servidor
        socket.on('message_', (msg) => { console.log(msg) });
        socket.on('test', (msg) => { console.log(msg) });
        socket.emit('message', 'teste');
    }

    return () => { if (socket) {socket.off('message');} };
  }, [socket]);

  // Fechando Snackbar
  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') { return; }
    setOpenMessage(false);
  };

  const doLogout = async () => {
    try{
      const logout = await axios.get(`${apiUrl}/v1/logout-user/?session=${localStorage.getItem('$s_ID')}`, { withCredentials: true });
      if(logout.request.status === 200)
      { 
        localStorage.getItem('$s_ID'); 
        localStorage.getItem('$u_n');
        localStorage.getItem('$u_photo');
        sessionStorage.getItem('$s_ID'); 
        sessionStorage.getItem('$u_n');
        sessionStorage.getItem('$u_photo');

        navigate('/login');
      }
    }
    catch(error) { console.log(error); }
  };

  // Checando token de autorização
  React.useEffect(() => {
    const verifyToken = async () => {
      const checkToken = await axios.get(`${apiUrl}/v1/check-auth/?session=${localStorage.getItem('$s_ID')}`, { withCredentials: true });
      if (checkToken.request.status !== 200)
      {
        // Se o token for inválido, exibe mensagem e redireciona
        addToQueue(`Token expirado ou invalido, deslogando usuário...`, 'warning', 'message');
        setTimeout(() => { doLogout(); }, 2000);
      }
    };

    verifyToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Mostrando os modulos ja instalados no banco de dados.
  useEffect(() => {
    const getListOfInstalledModules = async () => {
      // Puxando informações do banco de dados
      try{
        const validation = localStorage.getItem('$s_ID') || false;
        if(validation)
        {
          const listInstalledModules = await axios.get(`${apiUrl}/v1/listInstalledModules/?session=${localStorage.getItem('$s_ID')}`, { headers: { 'Content-Type': 'application/json' }, withCredentials: true });
          if(listInstalledModules.request.status === 200)
          { 
            setModules(listInstalledModules.data);

            // Filtrando modulos que são addons (componentes adicionais dinamicos)
            const addonModules = listInstalledModules.data.filter((f) => f.tipo === 'addon').map((mod) => ({...mod, Component: lazy(() => import(`${mod.componente}`))}));
            setAddons(addonModules);
          }
        }
      }
      catch (error) { 
        setTagMessage(`Ocorreu um erro técnico ao puxar informações sobre os modulos.`);
        setMessageType('error');
        setOpenMessage(true); 
        console.error("Erro ao buscar módulos:", error);
      }
    }

    // Executando a função
    getListOfInstalledModules();
  }, []);

  // Função para carregar as views dinamicamente.
  const loadView = (viewPath) => { return lazy(() => import(`${viewPath}`)); };

  return (
    <>
    <Routes>
      <Route path={`/`} element={<RedirectInstance toInstance={`${localStorage.getItem('$s_inst')}`}/>} />
      <Route path={`/${localStorage.getItem('$s_inst')}/`} element={<CheckRootRoute><MUINavBar addons={addons} /><Home /></CheckRootRoute>} />
      <Route path={`/${localStorage.getItem('$s_inst')}/modules`} element={<CheckRootRoute><MUINavBar addons={addons} /><EnableModule /></CheckRootRoute>} />
      <Route path={`/${localStorage.getItem('$s_inst')}/settings`} element={<CheckRootRoute><MUINavBar addons={addons} /><SettingsPage /></CheckRootRoute>} />

      {/* Mapear os módulos e suas views para criar rotas dinâmicas */}
      {modules.map((module) => Object.entries(module.views).map(([viewName, viewPath]) => {
        return (
          <>
            <Route
              key={`${module.id}-${viewName}`}
              path={`/${localStorage.getItem('$s_inst')}/${module.nome_link}/`}
              element={ 
                <>
                  <MUINavBar addons={addons}/>
                  <RenderSuspense loadView={loadView} setLoaded={setLoaded} key={module.id} viewName={viewName} viewPath={viewPath} />
                </>
              }
            />

            <Route
              key={`${module.id+1}-${viewName}`}
              path={`/${localStorage.getItem('$s_inst')}/${module.nome_link}/${viewName}`}
              element={ 
                <>
                  <MUINavBar addons={addons}/>
                  <RenderSuspense loadView={loadView} setLoaded={setLoaded} key={module.id} viewName={viewName} viewPath={viewPath} />
                </>
              }
            />
          </>
        )
      }
      ))}

      {loaded && <Route path={`/${localStorage.getItem('$s_inst')}/*`} element={<NotFoundPage />} />}
    </Routes>

    {/* Snackbar */}
    <Snackbar open={open} autoHideDuration={5000} onClose={closeSnack}>
        <Alert
            onClose={closeSnack}
            severity={messageType}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {tagMessage}
        </Alert>
    </Snackbar>
    </>
  );
}

export default App;
