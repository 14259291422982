import React, {useEffect, useState, useRef} from "react";
import PropTypes from 'prop-types';
import './settingsPage.css';
import { Autocomplete, Box, Button, CircularProgress, Input, Link, Tab, Tabs, TextField, Typography, Backdrop } from "@mui/material";
import TabPanel from "./components/MUI.TabPainel";
import { useSocket } from "../../componentes/socketContext";
import axios from "axios";
import { Dropdown } from 'primereact/dropdown';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import { Toast } from 'primereact/toast';
import { useLanguage } from "../../componentes/languageContext";

// Api url
const apiUrl = process.env.REACT_APP_API_URL;

TabPanel.propTypes = {children: PropTypes.node, index: PropTypes.number.isRequired, value: PropTypes.number.isRequired};

const a11yProps = (index) => { return { id: `vertical-tab-${index}`, 'aria-controls': `vertical-tabpanel-${index}`, }; }

// Módulo
const SettingsPage = () => {
    // States
    document.title = 'AICE Brasil ERP :: Configurações';
    const [value, setValue] = useState(0);
    const [email, setEmail] = useState('');
    const [pendingInvites, setPendingInvites] = useState({});
    const [activeUsers, setActiveUsers] = useState({});
    const [permissions, setPermissions] = useState({});
    const [backdropOpen, setBackdropOpen] = useState(false);
    const socket = useSocket();
    const [selectedLang, setSelectedLang] = useState({name: 'Português Brasil', code: 'pt-br'});
    const langs = [{name: 'Português Brasil', code: 'pt-br'}, {name: 'English', code: 'eng'}];
    const lang = useLanguage();
    const toast = useRef(null);

    // Função para exibir feedback 
    const show = (type, title, message) => {toast.current.show({ severity: type, summary: title, detail: message });};

    // Função para gerar sugestões de dominios de e-mails
    const emailDomains = ["@aice.com.sg", "@gmail.com", "@hotmail.com", "@yahoo.com", "@outlook.com", "@icloud.com", "@aol.com"];
    const getEmailSuggestions = (input) => {return email.includes("@") || email.length <= 4 ? [] : emailDomains.map((domain) => `${input}${domain}`);};
    const ChangeValue = (_, newValue) => {setValue(newValue);};

    const requestPrivMessage = async () => {await axios.get(`${apiUrl}/v1/returnMessageJustForMe/?session=${localStorage.getItem('$s_ID')}&socketID=${socket.id}`, {withCredentials: true})};

    const sendInvite = async () => {
        setBackdropOpen(true);
        const reqInvite = await axios.post(`${apiUrl}/v1/inviteNewUser/?session=${localStorage.getItem('$s_ID')}`, { email }, {withCredentials: true});
        if(reqInvite.request.status === 200) { show('success', 'Sucesso', 'Convite enviado com sucesso'); } else { show('error', 'Erro', 'Ocorreu um erro ao enviar o convite para este email'); }
        setBackdropOpen(false);
    }

    // Checando permissões 
    useEffect(() => {
        const getUserPermission = async () => {
            try{
                const getUserPermission = await axios.get(`${apiUrl}/v1/getUserPermission/?session=${localStorage.getItem('$s_ID')}`, { headers: {'Content-Type': 'application/json'}, withCredentials: true });
                if(getUserPermission.request.status === 200) { setPermissions(getUserPermission.data); }
            }catch (error) { console.error("Erro ao buscar módulos:", error); }
        }

        // Executando a função
        getUserPermission();
    }, []);

    // Renderizando
    if(lang === null || lang === undefined) { return (<div className='loadingModule'><CircularProgress size="3rem" /><span> Loading language... </span></div>) }
    else if(permissions === null || permissions === undefined) { return (<div className='loadingModule'><CircularProgress size="3rem" /><span></span></div>) }
    else return (
        <>
            <Backdrop sx={{color: '#fff', zIndex:999999 }} open={backdropOpen}><CircularProgress color="inherit" /></Backdrop>
            <Toast ref={toast} />
            {(permissions.role === 'developer' || permissions.role === 'gerência') && (
                <main className="config-page">
                    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'calc(100vh - 90px)', marginTop: 2 }} >
                        <Tabs orientation="vertical" variant="scrollable" value={value} onChange={ChangeValue} aria-label="Vertical tab" sx={{ borderRight: 1, borderColor: 'divider', width: 200 }}>
                            <Tab label="Configurações Gerais" {...a11yProps(0)} />
                        </Tabs>

                        <TabPanel value={value} index={0}>
                            <section className="users-painel">
                                <div className="adduser-section">
                                    <Typography sx={{ fontSize: '18px', fontWeight: 600, marginBottom: 1 }}>Usuários</Typography>
                                    <form>
                                        <Autocomplete freeSolo options={getEmailSuggestions(email)} inputValue={email} onInputChange={(event, newInputValue) => setEmail(newInputValue) }
                                        renderInput={(params) => (<TextField {...params} label="Email" required />)} renderOption={(props, option) => <li {...props} key={option}>{option}</li>} sx={{width: 300}}/>

                                        <Button fullWidth variant="contained" sx={{ width: 100, marginLeft: '5px' }} color="primary" disabled={email.length === 0} onClick={sendInvite}>
                                            {lang.invite_user_btn}
                                        </Button>
                                    </form>
                                </div>

                                <div className="manage-users">
                                    <div className="infos"><Typography>Pendentes: 0</Typography><Typography>Ativos: 0</Typography></div>
                                    <Link href="#" sx={{margin: '10px 20px'}}>Gerenciar usuários</Link>
                                </div>
                            </section>

                            <section className="language-painel">
                                <div className="selectlang-section">
                                <Typography sx={{fontSize: '18px', fontWeight: 600, marginBottom: 1}}>Linguagem</Typography>
                                <Dropdown value={selectedLang} onChange={(e) => setSelectedLang(e.value)} options={langs} optionLabel="name" placeholder="Select a City" className="w-full md:w-14rem" />
                                <Button fullWidth variant="contained" sx={{ width: 100, marginLeft: '5px' }} color="primary">Aplicar</Button>
                                </div>
                            </section>
                        </TabPanel>
                    </Box>
                </main>
            )}
        </>
    )
}

export default SettingsPage