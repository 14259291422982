import React from 'react';

const CaptchaBadge = () => {
    return (
        <div className="grecaptcha-badge">
            <div className="badge-cap"><img src="https://www.google.com/recaptcha/about/images/reCAPTCHA-logo@2x.png" style={{maxWidth:"35px"}} /></div>
            <div className="badge-policy" style={{padding: '5px 0', textAlign:'center'}}>
                <span>Protegido por reCAPTCHA</span>
                <div className="badge-terms" style={{textAlign:'center'}}>
                    <a href="https://www.google.com/intl/pt-BR/policies/privacy/" target="_blank">Privacidade</a><a href="https://www.google.com/intl/pt-BR/policies/terms/" target="_blank">Termos</a>
                </div>
            </div>
        </div>
    );
}

export default CaptchaBadge;